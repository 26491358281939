import React, { Component } from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import spotifyIcon from "../images/spotify.svg"
import podcastIcon from "../images/podcast.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"

class Post extends Component {
  render() {
    const post = this.props.data.wordpressPost
    const resolutions =
      post.featured_media.localFile.childImageSharp.resolutions.src

    return (
      <Layout>
        <SEO title="Podcast" />
        <div className="podcast">
          <div
            className="podcast-cover"
            data-sal="fade-left"
            data-sal-delay="600"
            data-sal-easing="ease"
          >
            <img
              className="podcast-cover__img"
              height="500"
              width="500"
              src={resolutions}
              alt="Podcast Cover"
            />
          </div>
          <div
            className="podcast-story"
            data-sal="slide-bottom"
            data-sal-delay="600"
            data-sal-easing="ease"
          >
            <div className="podcast-story__intro">
              <h1>{post.title}</h1>
              <div className="podcastStats">
                <p>
                  <span>{post.acf.duracion}</span> ·{" "}
                  <span>E{post.acf.episodio}</span>
                </p>
                <p>{post.acf.invitado}</p>
              </div>
            </div>
            <hr />
            <div className="podcast-story__buttons">
              <a
                className="spotify"
                href={post.acf.spotify}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={spotifyIcon} alt="Spotify Icon" />
                Escuchar en Spotify
              </a>
              <a
                className="apple"
                href={post.acf.apple_podcast}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={podcastIcon} alt="Podcast Icon" />
                Escuchar en Podcast
              </a>
            </div>
            <div
              className="podcast-story__paragraph"
              dangerouslySetInnerHTML={{ __html: post.content }}
            />
            <hr />
            <div className="podcast-story__footer">
              <div className="share">
                <p>Compartir:</p>
                <a
                  href={`https://www.facebook.com/sharer/sharer.php?u=https://parteaguas.org/${post.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Facebook
                </a>
                <a
                  href={`http://www.twitter.com/intent/tweet?url=https://parteaguas.org/${post.slug}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Twitter
                </a>
              </div>
              <div>
                <p>{post.date}</p>
              </div>
            </div>
            <hr />
            <div className="podcast-story__footer podcast-story__footerAlt">
              <a
                href={`/podcast/${post.acf.anterior_link}`}
                className="podcast-story__previous"
              >
                <p>{post.acf.anterior_titulo}</p>
                <svg
                  className="podcast-story__arrow"
                  width="11"
                  height="9"
                  viewBox="0 0 11 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M6.16957 8.99991L5.40394 8.24423L8.55593 5.09224H0.392578V3.99849H8.55593L5.40394 0.856445L6.16957 0.0908203L10.6241 4.54537L6.16957 8.99991Z"
                    fill="#4D4B4B"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

Post.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default Post

export const postQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      slug
      title
      content
      date(formatString: "Do MMMM")
      acf {
        duracion
        episodio
        invitado
        spotify
        apple_podcast
        anterior_titulo
        anterior_link
      }
      featured_media {
        localFile {
          childImageSharp {
            resolutions {
              src
              width
              height
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
